:root {
  --theme-purple: #9C55C2;
  --theme-dark: #626262;
  --black-color: #000;
  --link-grey-color: #989898; 
  --link-black-color: #151515;
  --screen-video-color: rgb(46, 46, 46);
  --black-color-opacity-2: rgba(0, 0, 0, .2);;
  --gray-color-opacity: rgba(148, 149, 153, .1);
  --google-button-login-color: #e4e4e4;
  --category-info-bg-gray-color: #F0F0F0;
  --light-gray-color: #949599;
  --product-bg-color: #C7C7C7;
  --search-bg-color: #EDEDED;
  --link-blue: #0d6efd;;
}

body {
  margin: 0;
  font-family: 'Work Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14.8px;
  padding: 0;
  box-sizing: border-box;
  color: var(--theme-dark);
  -webkit-font-smoothing: antialiased !important;
} 

a {
  text-decoration: none;
  color: var(--black-color);
}

p,
span {
  line-height: 1.6;
}

ul {
  color: var(--black-color);
}

i,
a,
p,
h1,
h2,
h3,
h4,
h5,
h6,
span {
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4 {
  color: var(--black-color);
}

h1 {
  @media (min-width: 768px) {
      font-size: 58px;
      font-weight: 900;
      margin: 30px 0;
  }
}

input:focus,
select:focus,
textarea:focus {
  outline: 0px;
}
.not-found {
  margin-top: 90px;
}

/* custom container class to keey all product and its details */
.container-product {
  margin: 0 auto;

  @media (min-width: 1200px) {
    padding: 0 10px;
    max-width: 2000px;
  }

  @media (min-width: 1500px) {
    padding: 0 60px;
  }
}

.container-single-product,
.profile-account-settings {
  padding: 140px 12px;

  @media (min-width: 1168px) {
    padding: 120px 12px;
  }
}

.slider-container {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  
  .slider {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;

    .slider-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 35px;
      height: 35px;
      border-radius: 12px;
      padding: 8px;
      background-color: #fff;
      border: 1px solid var(--gray-color-opacity);

      i {
        color: var(--theme-dark);
      }
    }

    .slider-icon-left {
      left: 6px;
    }
    
    .slider-icon-right {
      right: 6px;
    }
  
    .slider-images {
      width: 100%;
        
      img.slider-image {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }

  .slider-dots {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    
    .slider-dot {
      width: 6.5px;
      height: 6.5px;
      background-color: #bbb;
      border-radius: 50%;
      margin: 0 3px;
      cursor: pointer;
      transition: background-color 0.3s ease-in-out;
  
      &:hover {
        scale: 1.3;
        transition: transform 0.3s ease-in-out;
      }
    }
  
    .active {
      background-color: #333 !important;
    }
  }
}

.centered-links {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;

  a:hover {
    color: var(--black-color);
  }
}

.row,
.col-6,
.col-4 {
  margin: 0;
  padding: 0;
}

.Announcement {
  background-color: #1cb803;
  padding: 10px 0px 6px 0px;
}
.Announcement .col-md-6 p {
  color: white;
  margin-right: 6rem;
  font-size: 15px;
}
.Announcement .col-12 a {
  color: white;
  margin-right: 2rem;
  font-size: 15px;
}

.dropdown-container {
  position: fixed;
  top: 70px; 
  right: 9px;
  z-index: 2000;
  width: 230px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 6px rgb(204, 204, 204);

  .dropdown-arrow {
    position: absolute;
    top: -10px;
    right: 10px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff; 
    border: 0 0 6px rgb(204, 204, 204);
  }
  
  .dropdown {
    padding: 20px 0;
    color: var(--black-color);

    .user-info {
      padding: 0 12px;

      .user-name,
      .user-email {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .user-email {
        font-size: 14.5px;
        color: var(--link-grey-color);
      }
    }
  
    ul {
      margin: 0;
      padding: 0 4px;
      
      li {
        list-style: none;
        padding: 8px;
  
        &:hover {
          color: var(--black-color);
        }
        
        a {
          font-size: 16px;
        }

        i {
          padding-right: 8px;
        }
      }
    }

    .logout {
      padding: 0 4px;
      cursor: pointer;

      i {
        padding-right: 8px;
      }

      p {
        padding: 8px;
        color: var(--black-color);
        font-size: 16px;
      }
    }

    ul li:hover,
    .logout p:hover {
      background-color: var(--gray-color-opacity);
      border-radius: 8px;
      transition: .4s ease-in-out;
    }

    .dropdown-links {
      width: 95%;
      padding: 0 12px;

      a {
        font-size: 13.5px;
        color: var(--link-black-color);

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  hr {
    color: var(--product-bg-color);
  }

  @media (min-width: 768px) {
    right: 34px;
    width: 240px;
  }
}

/* Header */
.header-container {
  position: relative; /* Added */
  
  .header {
    position: fixed;
    background-color: #fff;
    width: 100%;
    top: 0;
    z-index: 2000;
    padding: 0 5px;
    box-shadow: 0 1px 1px 0 rgba(169, 169, 169, 0.14), 0 1px 1px -4px rgba(169, 169, 169, 0.2), 0 1px 1px 0 rgba(169, 169, 169, 0.12);
  
    .container-header {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
  
      .brand {
        display: inline-block;
  
        .navbar-brand {
          gap: 4px;
  
          .navbar-brand img {
            width: 100%;
            width: 40px;
          }
          .navbar-brand-text h5 {
            color: var(--link-black-color);
            font-size: 12px;
            text-transform: uppercase;
            font-weight: 900;
  
            @media (min-width: 1038px) {
              font-size: 15px;
            }
          }
        }
      }
  
      .search-bar {
        flex: 1;
        min-width: 150px;
        max-width: 600px;
        height: 2.5rem;
        cursor: pointer;
        background-color: var(--gray-color-opacity);
        border-radius: 24px !important;
        padding: 0 15px;
        display: flex;
        align-items: center;
  
        i {
          color: var(--light-gray-color);
        }
          
        input {
          background-color: transparent;
          border: none;
          height: 100%;
          width: 100%;
          margin-left: 5px;
  
          &::placeholder {
            color: var(--light-gray-color);
          }
        }
        
        @media (min-width: 1130px) {
          height: 42px;
        }
      }
  
      .search-results {
        position: absolute;
        top: calc(100% + 10px);
        left: 50%;
        transform: translateX(-50%); 
        width: 95vw;
        max-width: 800px;
        max-height: 78vh;
        border-radius: 20px;
        padding: 15px 2px;
        border: .5px solid var(--search-bg-color);
        background-color: #fff;
        overflow-y: auto;
        scrollbar-width: thin; 
        scrollbar-color: var(--google-button-login-color) transparent;

        @media (min-width: 865px) {
          width: 75vw;
          padding: 15px 20px;
        }
  
        .product {
          display: flex;
          align-items: center;
          gap: 15px;
          cursor: pointer;
          border-radius: 11px;
          padding: 5px 10px;
  
          img {
            object-fit: contain;
            width: 45px;
            height: 45px;
            border-radius: 50%;
          }
  
          .brand-platform-version {
  
            .brand-name {
              color: var(--black-color);
              font-size: 15px;
              font-weight: bold;
            }
  
            p {
              color: var(--light-gray-color);

              span {
                font-size: 13px;
              }
            }
          }
  
          &:hover {
            background-color: var(--gray-color-opacity);
          }
        }
      
        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }
      
        &::-webkit-scrollbar-thumb {
          background-color: var(--google-button-login-color); 
          border-radius: 6px;
        }
      
        &::-webkit-scrollbar-track {
          background-color: transparent; 
        }
      }
      
      .navbar-nav {
        flex-direction: row;
        gap: 15px;
  
        .nav-link {
          padding: 10px 0 5px;
          font-size: 15px;
          color: var(--link-grey-color) !important;
          white-space: nowrap;
  
          &:hover {
            color: var(--link-black-color) !important;
            font-weight: 600;
            transition: .1s ease-in-out;
          }
  
          @media (min-width: 1093px) {
            padding: 12px 0;
            font-size: 16px;
          }
        }
  
        .active .nav-link {
          color: var(--link-black-color) !important;
          font-weight: 600
        }
  
        @media (min-width: 1093px) {
          gap: 20px;
        }
      }
  
      .login {
          
        .navbar-Login-register {
          display: flex;
          align-items: center;
          font-size: 16px;
          color: var(--theme-dark) !important;
          white-space: nowrap;
          text-decoration: none;
  
          .login-button {
            padding: 0;
  
            @media (min-width: 949px) {
              padding: 0 16px;
            }
            
            .login-hover-show {
              border-radius: 8px;
              padding: 6px 12px;
              color: var(--black-color);
              font-weight: 600;
              
              &:hover {
                background-color: var(--search-bg-color);
                transition: .4s ease-in-out;
              }
            }
          }
          
          .register-button {
            display: none;
  
            .btn-theme-purple {
              background-color: var(--theme-purple);
              color: #fff;
              border-radius: 8px;
            }
  
            @media (min-width: 949px) {
              display: block;
            }
          }
        }
  
        .round-cover-avatar {
          cursor: pointer;
          border-radius: 20px;
          border: 1px solid var(--gray-color-opacity);
          padding: 4px;
  
          img {
            width: 28px;
            height: 28px;
            border-radius: 100%;
          }
  
          .avatar {
            background-color: var(--theme-purple);
            color: #fff;
            border-radius: 100%;
            height: 30px;
            width: 30px;
  
            P {
              font-size: 14px;
            }
          }
        }
      }
  
      .order:nth-child(3) {
        order: 4;
      }
  
      .order:nth-child(4) {
        order: 3;
      }
  
      @media (min-width: 1049px) {
        .order:nth-child(3) {
          order: 3;
        }
    
        .order:nth-child(4) {
          order: 4;
        }
      }
  
      @media (min-width: 639px) {
        width: 100%;
        gap: 0 50px;
      }
  
      @media (min-width: 865px) {
        gap: 0 100px;
      }
  
      @media (min-width: 1049px) {
        gap: 0 50px;
      }
    }
  
    @media (min-width: 768px) {
      padding: 4px 30px;
    }
  
    @media (min-width: 983px) {
      box-shadow: none;
    }
  
    @media (min-width: 2000px) {
      padding: 8px 80px 8px;
    }
  }
}

// CAROUSEL
.carousel {
  position: relative;
  width: 100%;

  .carousel-inner {
    position: relative;
    width: 100%;

    .carousel-item {
      display: none;
      position: relative;
      width: 100%;
      height: 78vh;

      @media (min-width: 768px) {
        height: 78vh;
      }

      &.active {
        display: block;
      }

      .carousel-text {
        position: absolute;
        top: 58%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: var(--black-color);
        width: 95%;

        @media (min-width: 768px) {
          top: 50%;
          width: 90%;
        }

        h1 {
          font-family: "Mulish", sans-serif;
          font-weight: 700;
          font-size: 1.8rem; 
          margin-bottom: 1.5rem;

          @media (min-width: 768px) {
            font-weight: 800;
            font-size: 3.5rem; 
          }
        }

        p { 
          font-size: 1rem; 
          color: var(--theme-dark);
          
          @media (min-width: 768px) {
            font-size: 1.3rem;
            width: 70%;
            margin: 0 auto;
          }
          
          @media (min-width: 1024px) {
            width: 55%;
            margin: 0 auto;
          }
        }

        animation: fadeInUp 1s forwards, fadeOutUp 1s forwards, moveUp 1s forwards;
      }
    }
  }

  button {
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
    border: none;
    border-radius: 7px;
    padding: 10px 12px;
    background-color: var(--theme-purple);
    color: #fff;
    cursor: pointer;

    @media (min-width: 768px) {
      bottom: 50px;
    }
  }

  .carousel-dots {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);

    @media (min-width: 768px) {
      bottom: 20px;
    }
    
    .dot {
      display: inline-block;
      width: 10px;
      height: 10px;
      background-color: #bbb;
      border-radius: 50%;
      margin: 0 6px;
      cursor: pointer;
  
      @media (min-width: 768px) {
        width: 8px;
        height: 8px;
        margin: 0 5px;
      }
    }
    
    .dot.active {
      background-color: var(--theme-dark);
      width: 15px;
      border-radius: 40%;
  
      @media (min-width: 768px) {
        width: 12px;
      }
    }  
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20px);
  }
}

@keyframes moveUp {
  from {
    bottom: 50%;
    opacity: 0;
    transform: translate(-50%, 50%);
  }
  to {
    bottom: 0%;
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

.browse {
  color: var(--black-color);
  font-weight: 900;
  text-transform: uppercase;
  padding: 10px 0 15px;

  @media (min-width: 998px) {
    padding: 30px 0;
  }
}

/* CATEORY SLIDER BUTTON STYLES */
.filter-category-slider {
  position: relative; 
  display: flex;
  align-items: center;
  padding-bottom: 24px;
  margin: 0 -12px;

  .category-slider-fading {
    display: none;
    position: relative;
    
    @media (min-width: 768px) {
      display: inline-block;
      
      &.left, 
      &.right {
        display: flex;
        align-items: stretch;
        width: 80px;
        height: 42px;
        position: absolute;
        top: 0;
        bottom: 0;
      }
  
      &.left {
        display: flex;
        justify-content: flex-start;
        left: 0;
        background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 60%, rgba(255, 255, 255, 1) 100%);
      }
  
      &.right {
        display: flex;
        justify-content: flex-end;
        right: 0;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 60%, rgba(255, 255, 255, 1) 100%);
      }
  
      .category-slider-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 42px;
        height: 42px;
        border-radius: 100%;
        cursor: pointer;
        
        &:hover {
          background-color: var(--gray-color-opacity);
          transition: .3s ease-in-out;
        }
      }
    }
  }
  
  @media (min-width: 768px) {
    margin: 0;
  }
}

/* CATEORY BUTTON STYLES */
.filter-category {
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  gap: 8px;

  &::-webkit-scrollbar {
    display: none;
  }

  a {
    font-size: 15px;
    white-space: nowrap;
  }
  
  .categoryButton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 0 12px;
    border-radius: 50px;
    border: 1px solid var(--gray-color-opacity);
    cursor: pointer;
    text-decoration: none;
    color: var(--link-black-color); 
    font-weight: 600;
    background-color: #fff;
    
    &:hover {
      background-color: var(--gray-color-opacity); 
      color: var(--link-black-color); 
      transition: .3s ease-in-out;
    }

    @media (min-width: 768px) {
      height: 42px;
      font-weight: 700;
    }
  }
  
  .activeCategory {
    background-color: var(--link-black-color);
    color: #fff;
    
    &:hover {
      background-color: var(--link-black-color);
      color: #fff; 
    }
  } 
}

/* SHOP */
.mobile-web-category {

  .section {
    padding-bottom: 6%;
    padding-top: 2%;

    .products {
    
      .category-button {
        padding: 5px 10px;
        border-radius: 15px;
      }
  
      .product-case {
        display: flex;
        justify-content: space-between;
        flex-flow: row wrap;
        gap: 35px 10px;
        
        .mobile-product-screen {
          display: flex;
          flex-direction: column;
          flex: 1 calc(100%);
          width: 100%;
          
          .shop-product {
             
            .shopBack {
              position: relative;
              overflow: hidden;
              width: 100%;
              background-color: #f3fbf7;
              border-radius: 18px;
              border: 1px solid var(--gray-color-opacity);
            }
          }

          @media (min-width: 420px) {
            flex: 0 1 calc(50% - 10px);
          }

          @media (min-width: 580px) {
            flex: 0 1 calc(30% - 10px);
          }

          @media (min-width: 992px) {
            flex: 0 1 calc(30% - 10px);
          }

          @media (min-width: 1060px) {
            flex: 0 1 calc(22% - 10px);
          }

          @media (min-width: 1400px) {
            flex: 0 1 calc(100% / 7 - 10px);
          }
        }

        .web-product-screen {
          display: flex;
          flex-direction: column;
          flex: 1 calc(100%);
          width: 100%;
          
          .shop-product {
            
            .shopBack {
              position: relative;
              overflow: hidden;
              width: 100%;
              background-color: #f3fbf7;
              border-radius: 18px;
              border: 1px solid var(--gray-color-opacity);
            }
          }

          @media (min-width: 680px) {
            flex: 0 1 calc(50% - 10px);
          }

          @media (min-width: 1100px) {
            flex: 0 1 calc(30% - 10px);
          }

          @media (min-width: 1800px) {
            flex: 0 1 calc(100% / 5 - 10px);
          } 
        }

        .slider-container .slider-icon,
        .slider-container .slider-dots {
          display: none;
        }
        
        .mobile-product-screen:hover .slider-container .slider-icon,
        .mobile-product-screen:hover .slider-container .slider-dots,
        .web-product-screen:hover .slider-container .slider-icon, 
        .web-product-screen:hover .slider-container .slider-dots {
          transition: transform .5s ease-in-out;
          display: block;
          z-index: 1000;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .product-brand-cat {
          display: flex;
          justify-content: flex-start;
          align-items: center;
      
          .product-brand {
            display: flex;
            justify-content: center;
            align-items: center;
            
            img {
              width: 43px;
              height: 43px;
              object-fit: contain;
              border-radius: 100% !important;
            }
          }
      
          .shoptext {

            p {
              color: var(--link-black-color);
            }
            
            &:hover>p {
              text-decoration: underline;
            }
      
            .category span {
              font-size: 13.3px;
              color: var(--light-gray-color);
            }
          }
        }
  
        @media (min-width: 986px) {
          gap: 40px 20px;
        }
      }
    }
  }
}
/* END OF SHOP */

/* NEWSLATTER */
.subscribe-section {
  margin-bottom: 20px;
  padding: 50px 30px;
  background-color: var(--black-color);

  .subscribe-head {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 40px;

    .head-text {
      color: #fff;
      margin: 10px;
      flex: 1 1 0%;
      
      h2 {
        font-size: 30px;
        letter-spacing: 0;
        line-height: 30px;
        margin: 0 0 13px;
      }
      p {
        font-size: 18px;
        line-height: 22px;
      }
    }
    
    .form-section {
      flex: 1 1 0%;
      margin: 10px;

      .input-group {
        justify-content: flex-end;
      }

      input[type="email"] {
        border: 0 none;
        border-radius: 25px;
        color: #9b9b9b;
        font-size: 16px;
        font-style: normal;
        height: 51px;
        letter-spacing: 0;
        width: 270px;
        max-width: 450px;

        @media (min-width: 768px) {
          width: 300px;
        }
      }

      button[type="button"] {
        background: var(--theme-purple);
        border: 0 none;
        border-radius: 0 10px 10px 0;
        color: #fff;
        height: 52px;
        letter-spacing: 0;
        padding: 0 14px;
        font-size: 12px;
      }
    }

    @media (min-width: 1100px) {
      flex-direction: row;
      justify-content: space-between !important;
    }
  } 
}
/* END OF NEWSLATTER */

/* SINGLE PRODUCT */
.container-single-product {

  .back {
    display: inline-block;
    border-radius: 8px;
    padding: 5px 12px;
    cursor: pointer;
    transition: all .3s ease-in-out;
    background-color: var(--gray-color-opacity);

    i {
      color: var(--link-black-color);
    }
  }

  .modal-back {
    position: fixed;
    z-index: 2001;
    top: 60px;
    left: 20px;
    background-color: #fff;

    i {
      color: var(--theme-dark);
    }
  }

  .single-product {
    padding-bottom: 80px;
    display: flex;
    flex-direction: column;
  
    .single-product-info,
    .single-product-flows {
      flex: 100%;
    }
    
    .single-product-info {
      background-color: var(--category-info-bg-gray-color);
      border-radius: 10px;
      padding: 30px 20px 50px;
  
      p,
      span {
        font-size: 14px;
      }
  
      h5 {
        font-size: 16px;
        font-weight: 600;
      }
  
      .brand-cat-web {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    
        .single-product-brand {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 40%;
          max-width: 120px;
          height: 120px;
          
          img {
            width: 95%;
            border-radius: 20px !important;
          }
        }
  
        .single-product-cat-web {
          width: 60%;
  
          h3 {
            font-weight: bold;
          }
        }
      }
  
      .rating {
  
        div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 5.5px 0;
        }
  
        .fas,
        .fa-star {
          font-size: 14px;
          color: #FFD700; 
          background-clip: content-box;
          padding: 2px; 
        }
  
        .fas.fa-star,
        .fas.fa-star-half-alt {
          color: #FFD700; 
        }
      }
  
      .interest {
        display: flex;
        justify-content: space-between;
        align-items: center;
  
        .switch {
          position: relative;
          display: inline-block;
          width: 42px;
          height: 22px;
        }
        
        .switch input { 
          opacity: 0;
          width: 0;
          height: 0;
        }
        
        .slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #ccc;
          -webkit-transition: .4s;
          transition: .4s;
        }
        
        .slider:before {
          position: absolute;
          content: "";
          height: 18px;
          width: 18px;
          left: 3px;
          bottom: 2px;
          background-color: white;
          -webkit-transition: .4s;
          transition: .4s;
        }
        
        input:checked + .slider {
          background-color: #2196F3;
        }
        
        input:focus + .slider {
          box-shadow: 0 0 1px #2196F3;
        }
        
        input:checked + .slider:before {
          -webkit-transform: translateX(18px);
          -ms-transform: translateX(18px);
          transform: translateX(18px);
        }
        
        /* Rounded sliders */
        .slider.round {
          border-radius: 40px;
        }
        
        .slider.round:before {
          border-radius: 50%;
        }
      }
    
      @media (min-width: 767px) {
        margin-right: 50px;
      }
    }
  
  
    .single-product-flows {

      .onboarding-rating-version-button {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 20px;

        .onboarding-rating-button {
          display: flex;
          align-items: center;
          gap: 30px;
          margin-bottom: 20px;
          
          .onboarding-button,
          .rating-button {
            display: inline-block;
            margin: 60px 0 0;
            cursor: pointer;
            transition: .1s ease-in-out;
  
            &:hover {
              color: var(--black-color); 
              font-size: 18px;
            }
            
            @media (min-width: 767px) {
              margin: 0;
            }
          }
          
          .onboarding-button.active,
          .rating-button.active {
            color: var(--black-color); 
            font-size: 18px; 
            font-weight: 700;
          }

          @media (min-width: 869px) {
            margin-bottom: 0;
          }
        }

        .version {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 20px;
  
          .version-text {
            font-weight: 900;
          }
  
          .version-button {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var(--search-bg-color);
            gap: 20px;
            width: 100%;
            height: 100%;
            border-radius: 10px !important;
            
            select {
              background-color: inherit;
              cursor: pointer;
              border-radius: 10px;
              padding: 8px 8px;
              width: 100%;
              height: 100%;
              border: none;
              border-radius: 10px !important;

              option {
                padding: 10px 5px;
                background-color: #fff;
              }
            }
          }
  
          @media (min-width: 992px) {
            justify-content: flex-end;
          }
        }

        @media (min-width: 869px) {
          flex-direction: row;
        }
      }
      
      .screen-video-button {
        display: flex;
        flex-direction: column;
        gap: 10px 0;
    
        p {
          font-size: 16px;
        }
  
        .screen-video-button {
          display: flex;
          flex-direction: row;
          gap: 10px;
          border-bottom: .5px solid var(--gray-color-opacity);
          cursor: pointer;
    
          .screen-button,
          .video-button {
            display: inline-block;
            padding: 8px 14px;
            margin: 20px 0 0;
            border-radius: 10px 10px 0 0;
            background-color: var(--category-info-bg-gray-color);
            color: var(--theme-dark);
            transition: .6s ease-in-out;

            &:hover {
              background-color: var(--screen-video-color);
              color: #fff;
            }
    
            @media (min-width: 767px) {
              margin: 0;
            }
          }

          .screen-button.active,
          .video-button.active {
            background-color: var(--screen-video-color);
            color: #fff; 
          }
        }
  
        @media (min-width: 992px) {
          flex-direction: row;
          justify-content: space-between;
        }
      }
  
      .product-flows {
        
        .categories-container {
          display: flex;
          flex-direction: column;
          gap: 20px; 
        
          .category {
            display: flex;
            flex-direction: column;
            gap: 10px; 

            p {
              font-size: 18px;
              font-weight: 600;
            }

            .screens-container {
              overflow-x: auto;
              white-space: nowrap;
              scrollbar-color: var(--black-color-opacity-2);
              &::-webkit-scrollbar {
                width: 6px;
                height: 6px;
              }
              &::-webkit-scrollbar-thumb {
                background-color: var(--black-color-opacity-2); 
                border-radius: 6px;
              }
              &::-webkit-scrollbar-track {
                background-color: transparent; 
              }

              .screens {
                display: flex;
                gap: 20px;
                margin-bottom: 5px;
              }
            }
          }
        }
      
        img.img-mobile, 
        img.img-web {
          border: 1px solid var(--gray-color-opacity);
          object-fit: contain;
          object-position: center;
          border-radius: 20px !important;
        }
      
        img.img-mobile {
          min-width: 275px;
          height: 570px;
        }
      
        img.img-web {
          width: 360px;
          min-height: 225px;
        }
      
        .videos {
          margin-top: 10px;
          width: 100%;
      
          video {
            width: 100%;
            border-radius: 7px;
          }
        }
      
        .no-single-video {
          display: flex;
          flex-direction: column;
          gap: 20px;
          justify-content: center;
          align-items: center;
          margin: 0 auto;
          padding: 100px 30px;
      
          i {
            font-size: 90px;
      
            @media (min-width: 768px) {
              font-size: 130px;
            }
          }
        }
      
        @media (min-width: 767px) {
          gap: 20px;
          width: calc(100vw - 369px);
        }
      }      
    }
  
    @media (min-width: 767px) {
      flex-direction: row;
  
      .single-product-info {
        max-width: 291px;
      }
    }
  
    @media (min-width: 1168px) {
      padding-bottom: 50px;
    }
  }

  .reviews {

    .no-comment {
      margin: 70px 0 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .fa-comment-alt-slash {
        font-size: 90px;
        margin-bottom: 20px;
  
        @media (min-width: 768px) {
          font-size: 130px;
        }
      }
    }

    .alert {
      padding: 45px 16px 16px;
    }

    .reviews-container {
      max-height: 700px;
      overflow-y: auto;
      scrollbar-color: var(--google-button-login-color);

      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--google-button-login-color); 
        border-radius: 6px;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent; 
      }

      .review {
        margin: 20px 10px 0;
    
        .comment {
          position: relative;
          border-radius: 0 10px 10px 10px;
          background-color: #f8f9fa;
          display: inline-block;
          min-width: 250px;
          width: auto;
          max-width: 100%;
          box-shadow: 5px 5px 7px 0px rgba(0, 0, 0, 0.08);
    
          &::before {
            content: '';
            position: absolute;
            top: -1px;
            left: -15px;
            width: 0;
            height: 0;
            border-bottom: 12px solid transparent;
            border-right: 15px solid #f8f9fa; 
          }
          
          .individual-ratings {
            display: flex;
            gap: 0 20px;
            position: absolute;
            top: 1px;
            left: 7px;
    
            .scores {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              line-height: 0;
    
              p {
                color: var(--light-gray-color);
              }
            }
            
            p {
              font-size: 10px;
            }
          }
    
          .individual-ratings.rating {
    
            .fas,
              .fa-star {
                font-size: 6px;
                padding: 0 1px;
                color: var(--light-gray-color);
              }
          }
    
          p {
            font-size: 12px;
          }
    
          span {
            position: absolute;
            bottom: 1px;
            right: 15px;
            font-size: 10px;
            color: var(--light-gray-color);
          }
        }
    
        @media (min-width: 768px) {
          .row .col-lg-6:nth-child(2) {
            padding: 0 30px !important;
          }
        }
    
        .top-margin {
          @media (max-width: 991px) {
            margin-top: 40px;
          }
        }
      }
    }

    .single-page-ratings {
  
      p {
        margin-top: 15px;
      }
      
      .select-rating {
        width: 100%;
        max-width: 600px;
    
        select {
          cursor: pointer;
          width: 100%;
        }
    
        .rounded {
          border-radius: 4px !important;
        }
    
        option {
          font-size: 15px !important;
        }
      }
    }

    .edit-review {
      background-color: var(--product-bg-color);
      cursor: pointer;
    }

    .no-user {
      margin-top: 0;
      
      @media (min-width: 768px) {
        margin-top: 70px;
      }
    }
  }
}

/* BLOG */
.blogs {
  .blog-header {
    margin: 80px 40px 70px;
  
    h2 {
      font-size: 35px;
      font-weight: 900;
  
      @media (min-width: 768px) {
        font-size: 50px;
        font-weight: 900;
      }
    }
  
    p {
      font-size: 18px;
    }
  }
  
  .blog-posts {
    .blog {
      margin-bottom: 80px;
  
      .blog-flex {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
  
        img{
          object-fit: contain;
          width: 100%;
          height: 380px;
          margin: 0 auto;
        }
        
        .blog-text {
          max-width: 370px;
  
          .blog-title,
          .blog-excerpt {
            margin: 20px;
          }
        
          .blog-excerpt {
            font-size: 16px;
            color: var(--theme-dark);
          }
        }
      }
      
      button {
        padding: 12px 18px;
        margin-left: 40px;
        border: none;
        border-radius: 8px;
        background-color: var(--theme-purple);
        color: #fff;
      }
    }
  }
}

// SINGLE BLOG
.single-blog {
  max-width: 900px;
  margin: 0 auto;

  .single-blog-title {
  
  
    .blog-title {
      margin-top: 150px;
      font-size: 30px;
      font-weight: bold;

      @media (min-width: 767px) {
        font-size: 50px;
        font-weight: 900;
      }

      @media (min-width: 1196px) {
        margin-top: 130px;
      }
    }
  }

  img {
    width: 100%;
    object-fit: contain;
    margin: 30px 0;

    @media (min-width: 767px) {
      height: 500px;
      margin: 50px 0;
    }
  }
}

// CONTACT
.contact {
  margin-top: 70px;
  
  h1 {
    font-size: 40px;
    margin: 40px 0;
    font-weight: bold;
  }

  h2 {
    font-size: 28px;
  }

  .section {
    padding: 40px 0;
    
    @media (min-width: 768px) {
      padding: 90px 0;

      .section-1 {
        text-align: center;
      }
    }

    .follow-social {
      margin-bottom: 80px;

      .social-icons {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 30px;
        margin-top: 30px;
  
        img {
          width: 30px;
          height: 30px;
          background-color: var(--black-color);

          @media (min-width: 768px) {
            width: 40px;
            height: 40px;
          }
        }
  
      }
    }
  }

  .form,
  .form-control {
    -ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=28, Direction=0, Color=#ABABAB)";/*IE 8*/
    -moz-box-shadow: 0 0 28px 4px rgba(171,171,171,0.2);/*FF 3.5+*/
    -webkit-box-shadow: 0 0 28px 4px rgba(171,171,171,0.2);/*Saf3-4, Chrome, iOS 4.0.2-4.2, Android 2.3+*/
    box-shadow: 0 0 28px 4px rgba(171,171,171,0.2);/* FF3.5+, Opera 9+, Saf1+, Chrome, IE10 */
    filter: progid:DXImageTransform.Microsoft.Shadow(Strength=28, Direction=135, Color=#ABABAB); /*IE 5.5-7*/
  }

  .form {
    border-radius: 10px;
    padding: 0 20px;
  
    .form-group input {
      height: 50px;
    }
  
    .button button {
      width: 100%;
      height: 46px;
      margin: 30px 0 20px;
      padding: 10px;
      background-color: #000;
      color: #fff;
      border-radius: 5px;
      border: none;
      box-shadow: 0 10px 53px 0 rgba(163, 204, 170, 0.1);
    }
    
  }
}

.about,
.terms,
.privacy {
  margin-top: 90px;

  h2 {
    margin: 30px 0;
    font-weight: 600;
  }

  .section {
    padding: 20px 0 40px;
  }
}

// ABOUT
.about {

  h1 {
    font-size: 40px;
    line-height: 1;
    margin: 40px 0;
  }
}

// TERMS
.terms,
.privacy {
  
  h2 {
    font-size: 40px;
  }

  h5 {
    color: var(--black-color);
    font-size: 17px;
    font-weight: bold;
  }

  p {
    color: var(--black-color);
  }
}

/* LOGIN */
.stretch-me {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  .register-me,
  .login-me,
  .reset-me {
    display: flex;
    flex-direction: row;
    height: 100vh;
  
    .image {
      display: none;
      
      @media (min-width: 1100px) {
        display: flex;
        overflow: hidden;
        flex: 1 1 0%;
        position: relative;
        text-align: center;

        img {
          width: 100%;
          object-fit: cover;
        }
      }
    }
  
    @media (min-width: 1100px) {
      justify-content: unset;
    }
  }
}

.return-to-login,
.resend-otp {
  color: var(--link-blue);
}

.resend-otp {
  float: right;
}

.resend {
  margin-top: 50px;
  cursor: pointer;
}


.confirmpassword-input-container {

  .error-message {
    color: #ff0000;
    font-size: 12px;
    margin-top: 5px;
  }
}
 
/* LOGIN - REGISTER BOTH FOR NORMAL AND PROMPT */
.form-register,
.form-confirm,
.form-login,
.form-reset {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1 0%;
  margin: 0 15px;
  height: 100%;

  p.heading,
  h4.heading {
    font-weight: 900;
    color: var(--black-color);
  }

  p.heading {
    font-size: 17px;
    margin: 0 0 20px;
    text-align: center;

    @media (min-width: 1100px) {
      font-size: 20px;
    }
  }

  h4.heading {
    font-size: 40px;
    margin: 0 0 40px;
  }
  
  .form-me {
    max-width: 400px !important;

    .email-input-container,
    .password-input-container {
      width: 100%;
    }

    .password-input-container {
      display: inline-block;
      position: relative;
    }

    .password-input-container i {
      position: absolute;
      top: 52%;
      right: 20px;
      cursor: pointer; 
    }

    label {
      color: var(--link-black-color);
    }

    input {
      width: 100%;
      height: 45px;
      padding: 12px 20px;
      margin-top: 20px;
      border: 1px solid #e4e4e4;
      border-radius: 7px;
      color: #8a8a8a;
      display: inline-block;
      box-sizing: border-box;  
    }

    input:focus {
      outline: 0px;
    }
    
    button {
      width: 100%;
      height: 45px;
      margin-top: 20px;
      padding: 12px 0;
      border: 0;
      border-radius: 7px;
    }
    
    button.register-button,
    button.login-button {
      color: white;
      background-color: var(--theme-purple);
    }

    button.google-button {
      transition: background-color .3s, box-shadow .3s;
      background-color: #fff;
      color: #5c6c75;
      border: .5px solid var(--category-info-bg-gray-color);
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
      background-repeat: no-repeat;
      background-position: 12px 14px;
      
      &:hover {
        box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
      }
      
      &:active {
        background-color: #eeeeee;
      }
      
      &:focus {
        outline: none;
        box-shadow: 
          0 -1px 0 rgba(0, 0, 0, .04),
          0 2px 4px rgba(0, 0, 0, .25),
          0 0 0 3px #c8dafc;
      }
    }

    p {
      margin-top: 20px;
    }

    p a {
      color: var(--link-blue);
    }

    @media (min-width: 768px) {
      width: 100%;
    }
  }
}

.form-login {
  p.prompt-text {
    text-align: center;
    font-size: 23px;
    color: var(--black-color) !important;
    font-weight: 900;
    margin-bottom: 30px;
  }

  .persist-reset {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .persist label {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      cursor: pointer;

      input {
        width: 15px;
        height: 15px;
        cursor: pointer;
      }
    }
    
    a {
      color: var(--link-blue);
      text-decoration: underline;
    }
  }
}

// PASSWORD VALIDATION
.password-validation {
  max-width: 400px;
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 9px;
  opacity: 0;
  transition: opacity 2s ease-in-out;

  .validation-item {
    padding: 1.8px 6px;
    border-radius: 30px;
    background: rgb(218, 218, 218);
    color: rgb(82, 100, 132);
    display: flex;
    align-items: center;
    
    i {
      padding-right: 7.5px;
      font-size: 10px;
    }

    p {
      font-size: 11px;
      margin: 0;
    }
  }

  .validation-item.valid {
    background: rgba(11, 177, 75, 0.22);
    color: rgb(11, 177, 75);
  }

  .validation-item.invalid {
    background: rgba(255, 0, 0, 0.2);
    color: rgb(255, 0, 0);
  }
}

.password-validation.visible {
  opacity: 1;
}

// PROFILE
.profile-account-settings {
  width: 95%;

  .sub-text {
    border-bottom: 2px solid var(--gray-color-opacity);

    h2 {
      color: var(--black-color);
      font-weight: 900;
      font-size: 28px;
      
      @media (min-width: 768px) {
        font-size: 35px;
      }
    }

    p {
      color: var(--link-grey-color);
    }
  }

  .profile-avatar {
    p {
      color: var(--black-color);
      font-size: 15px;
    }

    .round-cover-avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--theme-purple);
      border-radius: 50%;
      width: 110px;
      height: 110px;
      
      @media (min-width: 768px) {
        width: 140px;
        height: 140px;
      }

      h1 {
        font-size: 60px;
        color: #fff;
      }
    }
  }

  .form {
    padding: 20px 0;

    label {
      color: var(--black-color);
      font-size: 14.5px;
    }
    
    input {
      padding: 8px 10px;
      margin: 10px 0;
      font-size: 14px;
      border-radius: 7px;
    }

    .password-input-container {
      width: 100%;
      display: inline-block;
      position: relative;
    }
    .password-input-container i {
      position: absolute;
      top: 35%;
      right: 12px;
      cursor: pointer; 
    }
  }

  .form-container button.update-button,
  .form-container button.change-password-button {
    padding: 12px 18px;
    border: 0px;
    border-radius: 12px;
    margin-top: 20px;
    font-size: 13px;
    background-color: var(--link-black-color);
    color: #ffffff;
    height: 44px;
  }

  .form-container button.update-button {
    width: 85px;
  }

  @media (min-width: 992px) {
    width: 50%;
    max-width: 800px;
  }
}

/* CONFIRM MAIL */
.confirm-mail,
.verified-mail {
  height: 100vh;

  .confirm-center,
  .verified-center {
    width: 90%;
    height: 100vh;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .main {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
  
      h2 {
        color: var(--black-color);
        font-size: 20px;
        font-weight: 900;

        @media (min-width: 768px) {
          font-size: 40px;
        }

        @media (min-width: 992px) {
          font-size: 50px;
        }
      }

      p {
        padding-top: 0;
      }
    }
  
    p {
      padding-top: 90px;
    }

    @media (min-width: 992px) {
      width: 50%;
    }
  }
  
  img {
    width: 70%;

    @media (min-width: 768px) {
      width: 70%;
    }

    @media (min-width: 992px) {
      width: 50%;
    }
  }
}

/* LOGIN PROMPT */
.login-prompt {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  
  .white-background {
    background-color: #fff;
    border-radius: 7px;
    padding: 20px 0;
    margin: 0 10px;

    @media (min-width: 992px) {
      padding: 40px;
    }
  }
}

.app-wrap {
  display: flex;
  flex-direction: column;
  min-height: 100vh; 
}

/* FOOTER */
.footer {
  background-color: var(--black-color);
  margin-top: auto;
  padding: 16px;

  .row {


    .col-lg-4 {
      padding: 20px 0;
  
      @media (min-width: 992px) {
        padding: 0;
      }
    }

    .footer-logo {

      h5 {
        text-transform: uppercase;
        font-size: 15px;
      }
    }
  
    .social-icons{
      font-size: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 25px;

      @media (min-width: 992px) {
        justify-content: center;
      }
    }

    .year {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 25px;
      white-space: nowrap;

      @media (min-width: 992px) {
        justify-content: flex-end;
      }
    }
  }

  @media (min-width: 992px) {
    padding: 16px 30px;
  }
}